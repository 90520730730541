@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";


.wrapperList {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  &.line {
    grid-template-columns: 1fr
  }

}