.wrapper {
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  background: inherit;
}

.wrapperHead {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  background: #fff;
  padding: 20px;

}

.wrapperForm {
  display: flex;
  gap: 10px;
  width: 100%;
}