@import '@/Assets/sass/_variables';
@import '@/Assets/sass/mixins';

.wrapper {
	background: #ffffff;
	box-shadow: 0 5.6074px 27.4251px #ffffff05, 0 1.85692px 6.48173px #ffffff05;
	border-radius: 10px;
	min-width: 480px;
	padding: 20px;
	// max-width: 756px;
	flex: 1 0 756px;
	width: 100%;

	height: 231px;
	display: flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	position: relative;
	gap: 11px;

	&.noneFull {
		width: 100%;
	}
}

.isLineWrapper {
	width: 100%;
	max-width: 100%;
	flex: 1 0 100%;

	.description {
		width: calc(100% - 120px);
	}
}

.image {
	width: 249px;
	height: 145px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.groupButton {
	margin-top: 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	gap: 15px;

	button,
	a {
		font-weight: 700;
		font-size: 13px;
	}
}

.headerArticle {
	display: flex;
	align-items: center;
	justify-content: space-between;

	&.withDate {
		flex-wrap: wrap;

		.category {
			flex: 1 1 100%;
			order: 1;
		}
	}
}

.info {
	flex: 1;
	overflow: hidden;

	.date {
		font-weight: 400;
		font-size: 14px;
		line-height: 25px;
		text-align: justify;
		color: $primary_dark;
	}

	.title {
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		display: flex;
		align-items: center;
		margin-top: 10px;
		color: $RegularGray;
	}

	.category {
		font-weight: 400;
		font-size: 12px;
		margin-top: 7px;
		line-height: 14px;
		text-align: justify;
		letter-spacing: 0.300597px;
		color: $RegularGray;
		width: 100%;
		// order: 1;
	}

	.category {
		font-weight: 700;
		margin-bottom: 1px;
	}

	.description {
		font-weight: 400;
		font-size: 15px;
		line-height: 23px;
		display: flex;
		align-items: center;
		letter-spacing: 0.282191px;
		color: $Dark_admin;
		font-size: 15px;
		line-height: 20.575px;
		-webkit-line-clamp: 5;
		/* Число отображаемых строк */
		display: -webkit-box;
		/* Включаем флексбоксы */
		-webkit-box-orient: vertical;
		/* Вертикальная ориентация */
		overflow: hidden;
		p {
			margin: 0;
		}
	}
}

.moreDetailed {
	position: absolute;
	bottom: 12px;
	right: 20px;

	display: flex;
	align-items: flex-end;
	letter-spacing: 0.03em;

	a {
		font-weight: 500;
		font-size: 16px;
		color: $Purple;
		line-height: 19px;
	}
}

.select {
	display: flex;
	align-items: center;

	label {
		margin: 0;
	}
}

.status {
	display: flex;
	height: 100%;
	align-items: flex-start;
}
