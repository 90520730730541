@import '@/Assets/sass/_variables';
@import '@/Assets/sass/mixins';

.wrapper {
  position: relative;
  width: 100%;
  max-width: 175px;

  :global {
    .react-select__control {
      height: 40px;
      border-radius: 3px;
    }
  }
}




.option {
  padding: 0 20px;
  font-size: 14px;
  font-weight: 400;
  background: $BG_Gray;
  color: $GreyDark_3;
  line-height: 150%;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s ease 0s;

  &.simple {
    background: #fff;
    color: $RegularGray;
    font-size: 12px;
    padding: 7px 10px;
    font-weight: 400;
  }

  &.circle {
    border-top: 1px solid #EFEFEF;
  }

  @media (any-hover: hover) {
    &:hover {
      color: $RedMain;
    }
  }


  &.active {
    cursor: default;

    @media (any-hover: hover) {
      &:hover {
        color: $GreyDark_3;

      }
    }

    .check {
      border: 1px solid $RedMain;

      &::after {
        content: '';
        width: 9px;
        height: 9px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        background: red;
      }
    }
  }
}

.check {
  width: 14px;
  height: 14px;
  border: 1px solid $GrayDark;
  border-radius: 100%;
  position: relative;
}

.label {
  display: block;
  margin-bottom: 12px;
}


.error {
  span {
    position: absolute;
    font-size: 10px !important;
    text-transform: none !important;
    font-weight: 400 !important;
    color: $RedMain;
    left: 12px;
  }

}