@import '@/Assets/sass/_variables';
@import '@/Assets/sass/mixins';


.wrapper {
  display: flex;
  gap: 10px;
  background-color: #fff;
}

.label {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  // width: 100%;

  &:hover {
    opacity: 0.8;
  }
}


.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;



  &:checked {
    ~.check {
      border: 1px solid $RedMain;

      &::after {
        content: '';
      }
    }

    ~.title {
      color: $RedMain
    }
  }
}

.check {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  position: relative;
  flex: 0 0 auto;
  transition: all 0.3s ease 0s;

  border: 5.5px solid $GreyLight;

  &::after {
    width: 7px;
    height: 7px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    background: $RedMain;
  }
}

.title {
  position: relative;
}

.inputValue {
  position: relative;
  z-index: 10;
  border-radius: 3px;
  background: #F4F4F9;
  width: 130px;
}