@import '@/Assets/sass/variables';
@import '@/Assets/sass/mixins';

.wrapper {
  position: relative;
}

.white {
  label {
    background: #fff;
  }
}