@import "../../_variables"

.SchoolCard
  background: #FFFFFF
  box-shadow: 0 5.6074px 27.4251px #ffffff05, 0 1.85692px 6.48173px #ffffff05
  border-radius: 10px
  min-width: 480px
  min-height: 185px
  padding: 20px 20px 20px 0
  margin: 9px 0
  width: calc(50% - 50px)
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  justify-content: space-between
  position: relative
  .leftBlock
    margin-right: 20px
    img
      width: 250px
      height: 140px
    .groupButton
      display: flex
      flex-direction: row
      flex-wrap: nowrap
      margin: 0 -15px
      button, a
        text-decoration: none
        width: calc(50% - 14px)
        margin: 15px 7px
        height: 30px
        background: #FFFFFF
        box-shadow: 0 1px 2px #00000010, 0 1.85692px 6.48173px #00000010
        border-radius: 3px
        border: none
        outline: none
        font-weight: 700
        font-size: 13px
        line-height: 100%
        display: flex
        align-items: center
        color: $RegularGray
  .rightBlock
    width: 80%
    .date
      font-weight: 400
      font-size: 14px
      line-height: 25px
      text-align: justify
      color: $primary_dark
    .title
      font-weight: 500
      font-size: 20px
      line-height: 24px
      display: flex
      align-items: center
      color: $RegularGray
    .category
      font-weight: 400
      font-size: 12px
      line-height: 14px
      text-align: justify
      letter-spacing: 0.300597px
      color: $RegularGray
      margin: 10px 0 15px
    .description
      font-weight: 400
      font-size: 15px
      line-height: 23px
      display: flex
      align-items: center
      letter-spacing: 0.282191px
      color: $Dark_admin
  .moreDetailed
    position: absolute
    bottom: 12px
    right: 20px
    font-weight: 500
    font-size: 16px
    line-height: 19px
    display: flex
    align-items: center
    letter-spacing: 0.03em
    color: $RegularGray
  .InputCheckboxSwitch
    position: absolute
    top: 15px
    right: 0
    display: flex!important
    align-items: center
    width: 100px
    justify-content: space-around
  @media screen and (max-width: 1200px)
    width: 100%
  &.noneFull
    width: 100%

