@import '@/Assets/sass/variables';
@import '@/Assets/sass/mixins';


.wrapper {
  width: 100%;
}

.headersWrapper {
  padding: 0 20px;
  background: #fff;
  border-radius: 10px 10px 0 0;

}

.headers {
  display: inline-flex;
  background: #fff;
  gap: 50px;
  min-width: 747px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    border-radius: 2px;
    width: 100%;
    height: 3px;
    bottom: 0px;
    left: 0;
    background: $Grey_admin;
  }

}

.header {
  font-size: 20px;
  position: relative;
  font-style: normal;
  color: $RegularGray;
  font-weight: 400;
  padding: 14px 0;
  cursor: pointer;
  padding-top: 29px;

  &.tabActive {
    font-weight: 700;
    cursor: default;

    &::after {
      content: '';
      position: absolute;
      border-radius: 2px;
      width: 100%;
      height: 3px;
      bottom: -0px;
      left: 0;
      background: $Purple;
      z-index: 1;
    }

  }
}