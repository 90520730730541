@import '@/Assets/sass/_variables';

.wrapper {
  min-width: 190px;
  height: 128px;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  transition: 300ms;

  &:hover {
    box-shadow: 0 15px 158px rgba(0, 0, 0, 0.04), 0 14.1602px 65.85px rgba(0, 0, 0, 0.0499055), 0 5.6074px 27.4251px rgba(0, 0, 0, 0.0503418), 0 1.85692px 6.48173px rgba(0, 0, 0, 0.0462464);
  }



  &.noneFull {
    width: calc(50% - 50px);
  }
}

.title {
  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.282191px;
  color: $Dark_Gray;
}

.count {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: $Dark_Gray;
}

.levelRow {
  display: flex;
}

.level {
  width: 65px;
  height: 20px;
  background: $BG_Gray;
  border-radius: 3px;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.300597px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin: 0 5px;
  }

  &.up {
    color: green;

    svg {
      transform: rotate(270deg);

      path {
        stroke: green;
      }
    }
  }

  &.down {
    color: $Accent_Red;
  }
}