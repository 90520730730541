@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";


.wrapper {}

.container {
  margin-bottom: 15px;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 15px 20px;

  button:disabled,
  button[disabled] {
    cursor: default !important;

    * {
      cursor: default !important;
    }
  }

}

.containerBackground {
  position: relative;
  background: #FFFFFF;
  height: 733px;
  overflow-y: auto;
  @include scrollbars(4px, #ccc, white);
  // background: url('../../../../shared/assets/chat/svg/background.svg');

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: url('../../../../shared/assets/chat/svg/background.svg');
    opacity: 0.1;
  }
}

.chat {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  gap: 20px;
  position: relative;


  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: url('../../../../shared/assets/chat/svg/background.svg');
    opacity: 0.1;
  }
}

.form {
  height: 80px;
  display: flex;
  align-items: center;

  button {
    background-color: inherit;
    border: none;
    outline: none;
  }
}

.inputContainer {
  display: flex;
  align-items: center;

  flex: 1;
}


.wrapperEmoji {
  position: relative;

}

.emoji {
  position: absolute;
  right: 0;
  top: -25px;
  transform: translateY(-100%);
}

.input {
  background-color: #fff;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0.282191px;
  color: #676C79;
  width: 100%;
  height: 28px;
  max-height: 56px;
}