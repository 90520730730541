@import '@/Assets/sass/_variables';
@import '@/Assets/sass/mixins';


.wrapper {
  flex: 1;

  .form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    background: inherit;
  }
}



.wrapperHead {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  background: #fff;
  padding: 20px;

}

.wrapperHead {}

.formWrapper {
  display: flex;
  gap: 10px;
  width: 100%
}