@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";


.wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 701px;
  gap: 20px;
}

.left {
  flex: 0 0 430px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @include scrollbars(4px, #ccc, white);
}

.center {
  position: relative;
  flex: 1;
}

.right {
  flex: 0 0 292px;
  @include scrollbars(4px, #ccc, white)
}