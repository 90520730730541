@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";


.productsList {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  gap: 20px;

}