@import '@/Assets/sass/_variables';
@import '@/Assets/sass/mixins';

.wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}

.icon {
  svg {
    margin: 0;
  }

  &.active {
    svg {

      path,
      rect {

        fill: $RedMain;
      }
    }
  }
}