@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";

.list {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.select {
  min-width: 125px;
}