.chat
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  justify-content: space-between
  align-items: flex-start
  width: 100%
  height: 650px
  .chat__left
    overflow-y: auto
    width: 25%
    height: 100%
    margin-right: 20px
  .chat__right
    overflow-y: auto
    width: 15%
    height: 100%
    margin-left: 20px
  .chat__center
    overflow-y: auto
    width: calc(60% - 40px)
    height: 100%
  .chat__block
    display: flex
    // width: calc(100% - 40px)
    margin-bottom: 15px
    padding: 15px 20px
    background: #FFFFFF
    border-radius: 10px
    &.noneMargin
      margin: 0
    &.nonePadding
      padding: 0
    &.column
      flex-direction: column
    .chat__list__title
      cursor: pointer
      .chat__list_button
        background: none
        border: none
        font-weight: 500
        font-size: 20px
        line-height: 24px
        display: flex
        align-items: center
        text-align: center
        color: #695CB8
        svg
          &.r90
            transform: rotate(90deg)
    .chat__list
      &.show
        display: none
      .chat__list__item
        display: flex
        flex-direction: row
        align-items: center
        margin-bottom: 10px
        cursor: pointer
        &:last-child
          margin-bottom: 0
        .chat__list__item__avatar
          margin-right: 10px
          border-radius: 50%
        .chat__list__item__info
          font-weight: 500
          font-size: 16px
          line-height: 19px
          .chat__list__item__info__name
            font-weight: 500
            font-size: 14px
            line-height: 16px
          .chat__list__item__info__message
            font-weight: 500
            font-size: 14px
            line-height: 16px
    .message__list
      width: 100%
      height: 100%
      overflow-y: auto
      .message__list__item
        display: flex
        flex-direction: row
        align-items: center
        margin-bottom: 10px
        justify-content: flex-start
        &.user
          justify-content: flex-end
          .message__list__item__message
            margin-right: 15px
            margin-left: 76px
            background: #F4F4F9
            border-radius: 8px 8px 0 8px
            &:before
              border-left: 12.5px solid #F4F4F9
              border-right: none
              left: auto
              right: -12.5px
        &:last-child
          margin-bottom: 0
        .message__list__item__avatar
          text-align: center
          svg
            width: 62px
            height: 62px
          p
            margin: 0
        .message__list__item__message
          background: #D4D4D8
          border-radius: 8px 8px 8px 0
          padding: 15px
          margin-left: 15px
          margin-right: 76px
          font-weight: 400
          font-size: 15px
          line-height: 23px
          display: flex
          align-items: center
          letter-spacing: 0.282191px
          color: #2A3042
          position: relative
          &:before
            content: ''
            position: absolute
            width: 0
            height: 0
            border-top: 25px solid transparent
            border-right: 12.5px solid #D4D4D8
            left: -12.5px
            bottom: 0

    &.chat__input
      height: 80px
      input
        width: 100%
        font-weight: 600
        font-size: 18px
        line-height: 23px
        display: flex
        align-items: center
        letter-spacing: 0.282191px
        color: #676C79
      button
        background: none
        border: none
  .user_info
    .chat__block
      justify-content: space-around
      svg
        margin: 0 auto
        width: 104px
        height: 104px
      .user_info__item
        .user_info__item_title
          font-weight: 700
          font-size: 14px
          line-height: 17px
          display: flex
          align-items: center
          color: #676C79
        .user_info__item_value
          font-weight: 400
          font-size: 15px
          line-height: 23px
          display: flex
          align-items: center
          letter-spacing: 0.282191px
          color: #676C79
    button
      width: 100%
      height: 40px
      margin-bottom: 10px
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1)
      border-radius: 3px
      border: none
      background: #FFFFFF




