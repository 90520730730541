@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";

.wrapper {
  // max-width: 368px;
  flex: 1 0 368px;
  flex: 0 0 calc(25% - 10px);
  width: 100%;
  min-height: 355px;
  background: #FFFFFF;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 1.85692px 6.48173px rgba(0, 0, 0, 0.0462464);
  border-radius: 10px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
}


.topBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;

  .inputCheckbox+label {
    margin: auto 9px auto 0;
  }


}

.info {
  text-align: center;
}

.image {
  // max-width: 358px;
  width: 100%;
  height: 153px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px
  }
}


.title {
  margin-top: 15px;
  @include H2_Admin($RegularGray);
}

.description {
  @include Body2($Dark_admin);
  height: 50px;
  overflow: hidden;
  word-break: break-word;
}

.price {
  margin-top: 10px;
  text-align: center;
  @include buttons($Purple);
}

.groupButton {
  height: 60px;
  border-top: 1px solid $GreyLight_very;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  margin-top: auto;
  gap: 10px;

  button {
    max-width: 150px;
  }
}

.isLine {
  max-width: 100%;
  height: 187px;
  flex-wrap: wrap;
  display: flex;
  gap: 11px;
  padding: 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 1 0 100%;

  .topBlock {
    flex-direction: column;
    gap: 10px;
  }

  .info {
    // max-width: calc(100% - 350px);
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    order: 1;
    text-align: left;
    padding: 0 15px;
    flex: 1;

  }

  .title {
    margin-top: 0;
    font-weight: 500;
    font-size: 20px;
  }

  .description {
    margin-top: 9px;
    font-weight: 400;
    line-height: 22.575px;
    height: 69px;
    overflow-y: auto;
  }

  .price {
    text-align: left;
  }

  .groupButton {
    border-top: none;
    // width: 249px;
  }

  .status {
    order: 2;
  }

  .select {
    display: flex;
    align-items: center;
    height: 100%;

    label {
      margin: 0;
    }
  }

  .image {
    width: 249px;
    height: 97px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
  }
}