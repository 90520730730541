@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";


.list {
  display: flex;
  gap: 20px;

}

.table {
  height: 524px;
  overflow: auto;
  background: #fff;
  @include scrollbars(4px, $Dark_Gray, $primary_dark);

  tbody {
    tr {
      height: 78px;
    }
  }

}

.filter {
  margin-top: 40px;
  margin-bottom: 20px;
}

.firstName {
  color: $Dark_admin;
}

.link {
  display: block;

  @media (any-hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
}

.pagination {
  margin-top: 10px;
}