@import '@/Assets/sass/variables';
@import '@/Assets/sass/mixins';


.wrapper {
  display: flex;
  gap: 30px;
  flex-direction: column;
}

.backgroundWrap {
  border-radius: 10px;
  background: #FFF;
  padding: 20px;
}

.sections {
  display: flex;
  align-items: center;
  gap: 25px;
}

.table {
  margin-top: 24px;

  th {
    font-size: 18px;
  }

  td {
    padding: 16px 12px;
  }

  th {
    padding: 10px 12px;

  }

}




.buttons {
  display: flex;
  align-items: center;

  gap: 10px;
}

.filterButton {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border: none;
  border-radius: 3px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 23px;
    height: 23px;
    flex: 0 0 23px;
  }

  &.activeButton {

    path {
      fill: $RedAccent;
      stroke: $RedAccent;
    }
  }
}

.wrapperFilter {
  background: #fff;
  padding: 31px 20px 15px 20px;
  min-width: 284px;
  color: black;

  .title {
    @include Body_admin($RegularGray);
    margin-bottom: 18px;
  }

  .titleFilter {
    @include caption($RegularGray);
    margin-bottom: 17px;
  }

  .buttons {
    margin-top: 30px;
    gap: 8px;
  }


}