@import '@/Assets/sass/variables';
@import '@/Assets/sass/mixins';

.wrapper {
	position: relative;

	&.white {
		padding: 10px;
		font-size: 12px;
		line-height: 14px;
		display: flex;
		align-items: center;
		letter-spacing: 0.300597px;
		color: $Dark_Gray;
		border-radius: 3px;
		border: none;
		outline: none;
		background: #fff;
	}

	.inputError {
		border: 1px solid $RedMain !important;
	}
}

.label {
	display: block;
	margin-bottom: 12px;
}

.input {
	width: 100%;
	height: 40px;
	background: $Grey_admin;
	border-radius: 3px;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	display: flex;
	align-items: center;
	letter-spacing: 0.300597px;
	color: $RegularGray;
	flex: none;
	order: 0;
	flex-grow: 0;

	&.white {
		padding: 20px;
		font-size: 12px;
		line-height: 14px;
		display: flex;
		align-items: center;
		letter-spacing: 0.300597px;
		color: $Dark_Gray;
		border-radius: 3px;
		border: none;
		outline: none;
		background: #fff;
	}

	&.iconInput {
		padding-left: 30px;
	}

	&.small {
		height: 34px;
	}

	&.animate {
		&::placeholder {
			color: transparent;
		}

		&.placeholderInput {
			margin-top: 25px;
		}

		& ~ .label {
			margin: 0 12px;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%) translateX(0);
			max-width: 100%;

			box-sizing: border-box;
			pointer-events: none;
			// color: $color-placeholder-field;
			transition: font-size 0.2s ease-in, line-height 0.2s ease-in,
				top 0.2s ease-in;
			transition: all 0.3s ease 0s;

			&:before {
				content: '';
				margin-top: 0;
				position: absolute;
				left: 0;
				top: 50%;
				width: 100%;
				height: 0;
				// border-top: 2px solid $bg-field;
			}

			span {
				position: relative;
			}
		}
	}
}

.error {
	span {
		position: absolute;
		font-size: 10px !important;
		text-transform: none !important;
		font-weight: 400 !important;
		color: $RedMain;
		left: 12px;
	}
}

.icon {
	position: absolute;
	top: 50%;
	transform: translate(50%, -50%);
	display: flex;
	align-items: center;

	svg {
		margin: 0 !important;
		height: auto !important;
		width: 17px;
		padding: 0 !important;
	}
}

.glassWrapper {
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;

	svg {
		margin: 0 !important;
		height: auto !important;
		width: 20px;
		padding: 0 !important;
	}
}
