@import '@/Assets/sass/variables';
@import '@/Assets/sass/mixins';

.wrapper {
  display: flex;
  height: 100vh;
  align-items: center;
  background: #FFFFFF;
}

.content {
  width: 50vw;
  height: 100%;
  margin-right: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.background {
  width: 50vw;
  height: 100%;
  background: $Dark_admin;

  svg {
    height: 100%;
  }

}