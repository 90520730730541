@import "_variables";
@import "mixins";



@include font-face('Gilroy', '../../app/fonts/Gilroy-Black', 900);
@include font-face('Gilroy', '../../app/fonts/Gilroy-ExtraBold', 800);
@include font-face('Gilroy', '../../app/fonts/Gilroy-Bold', 700);
@include font-face('Gilroy', '../../app/fonts/Gilroy-SemiBold', 600);
@include font-face('Gilroy', '../../app/fonts/Gilroy-Medium', 500);
@include font-face('Gilroy', '../../app/fonts/Gilroy-Regular', 400);
@include font-face('Gilroy', '../../app/fonts/Gilroy-Light', 300);