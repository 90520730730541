@import "../_variables";

.App-header {
    height: 84px;
    background: $primary_dark;
    border-left: 1px solid #EBEBF450;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    z-index: 9999;

    .header-leftBlock {
        display: flex;

        button {
            border: none;
            background: none;
            outline: none;
            cursor: pointer;
            margin-right: 16px;
        }
    }

    .header-rightBlock {
        display: flex;
        margin-right: 10px;

        .buttonGroup {
            display: flex;
            align-content: center;
            align-items: center;

            >div {
                margin: 17.5px;
            }

            button,
            div {
                border: none;
                // background: none
                outline: none;
                cursor: pointer;
                color: $Dark_Gray;
                padding: 0;

                svg {
                    width: 25px;
                    height: 25px;
                }

                &.notify {
                    .subMenu {
                        width: 292px !important;
                        height: auto !important;

                        .toClose {
                            position: fixed;
                            bottom: -32px;
                            background: #F4F4F9;
                            border-radius: 0 0 3px 3px;
                            width: calc(100% - 12px);
                            margin: 0 -20px;
                            padding: 6px;
                            text-align: center;
                            box-shadow: 0 1px 2px #00000010, 0 1.85692px 6.48173px #00000040;
                        }
                    }
                }

                &.lang,
                &.notify {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    position: relative;
                    cursor: pointer;

                    .subMenu {
                        position: fixed;
                        top: 74px;
                        width: 193px;
                        height: 154px;
                        background: white;
                        border-radius: 3px;
                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        justify-content: space-evenly;
                        align-items: flex-start;
                        padding: 20px;
                        margin-left: -32px;
                        box-shadow: 0 1px 2px #00000010, 0 1.85692px 6.48173px #00000040;
                        transform: scale(0);
                        transition: 300ms;

                        &.active {
                            transform: scale(1);
                        }

                        .triangle {
                            position: absolute;
                            left: -32px;
                            top: -32px;
                            transform: translate3d(59.5px, 0, 0);

                            &:before,
                            &:after {
                                box-sizing: content-box;
                                position: absolute;
                                border: 8px solid transparent;
                                height: 0;
                                width: 1px;
                                content: "";
                                z-index: -1;
                                left: -8px;
                                border-bottom-color: white;
                            }
                        }

                        input {
                            position: absolute;
                            z-index: -1;
                            opacity: 0;

                            &+label {
                                display: inline-flex;
                                align-items: center;
                                user-select: none;

                                &::before {
                                    content: '';
                                    display: inline-block;
                                    width: 16px;
                                    height: 16px;
                                    flex-shrink: 0;
                                    flex-grow: 0;
                                    border: 8px solid $RegularGray;
                                    border-radius: 50%;
                                    margin-right: 30px;
                                    background-repeat: no-repeat;
                                    background-position: center center;
                                    background-size: 50% 50%;
                                }
                            }

                            &:disabled {
                                &+label {
                                    &:before {
                                        background-color: #e9ecef;
                                    }
                                }
                            }

                            &:checked {
                                &+label {
                                    color: $RedAccent;

                                    &:before {
                                        width: 12px;
                                        height: 12px;
                                        border: 10px solid $RedAccent;
                                    }
                                }
                            }

                            &:focus {
                                &:not(:checked) {
                                    &+label {
                                        &:before {
                                            border-color: $RedAccent_10_percent;
                                        }
                                    }
                                }

                                &+label {
                                    &:before {
                                        box-shadow: 0 0 0 0.2rem $RedAccent_10_percent;
                                    }
                                }
                            }

                            &:not(:disabled) {
                                &:active {
                                    &+label {
                                        &:before {
                                            background-color: $RedAccent_10_percent;
                                            border-color: $RedAccent;
                                        }
                                    }
                                }

                                &:not(:checked) {
                                    &+label {
                                        &:hover {
                                            color: $RedAccent_50_percent;

                                            &:before {
                                                border-color: $RedAccent_50_percent;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .profileBlock {
            display: flex;
            color: white;
            margin-left: 80px;

            .blockInfo {
                text-align: end;
                margin-right: 20px;
                display: flex;
                align-items: flex-end;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: center;
                white-space: nowrap;

                .name {
                    font-size: 20px;
                    line-height: 24px;
                    color: white;
                    position: relative;
                }

                .role {
                    font-size: 15px;
                    line-height: 23px;
                    text-align: center;
                    letter-spacing: 0.282191px;
                    color: $BG_Gray;
                    position: relative;
                    display: flex;
                    align-items: center;

                    svg {
                        margin-left: 10px;
                    }
                }
            }

            @media screen and (max-width: 1175px) {
                margin-left: 10px;

                img {
                    display: none;
                }
            }
        }
    }
}