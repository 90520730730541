@import '@/Assets/sass/_variables';
@import '@/Assets/sass/mixins';


.wrapper {
  height: 100%;
  position: relative;


}


.wrapperInfo {
  margin-bottom: 20px;
  padding: 15px 20px;
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 582px;
  // justify-content: space-between;
  gap: 20px;

  svg {
    margin: 0 auto;
    max-width: 104px;
    width: 100%;
    height: 104px;
    height: auto;
  }
}

.image {
  position: relative;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  height: 104px;
  width: 104px;
  border: 3px solid #6A6667;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 7px;


}

.buttons{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.skeleton {
  @include skeleton()
}