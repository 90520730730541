@import "../_variables"


.BigCardAnalytics
  // width: calc(100% - 40px)
  // height: 273px
  background: white
  border-radius: 10px
  transition: 300ms
  padding: 20px
  margin: 10px 0
  .headerRow
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    justify-content: space-between
    align-items: flex-start
    .leftBlock
      .title
        font-weight: 500
        font-size: 20px
        line-height: 24px
        display: flex
        align-items: center
        color: $Dark_Color
        margin: 0
        padding: 0
      .description
        font-weight: normal
        font-size: 18px
        line-height: 23px
        display: flex
        align-items: center
        letter-spacing: 0.282191px
        color: $Dark_Gray
        margin: 0
        padding: 0
    .rightBlock
      display: flex
      flex-direction: row
      flex-wrap: nowrap
      align-content: center
      align-items: center
      .react-datepicker-wrapper
        width: auto
        .react-datepicker__input-container
          width: auto
          button
            width: 110px
      button
        width: 35px
        height: 29px
        background: $BG_Gray
        border: 1px solid $BG_Gray
        box-sizing: border-box
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 1.85692px 6.48173px rgba(0, 0, 0, 0.0462464)
        border-radius: 3px
        display: flex
        flex-direction: row
        flex-wrap: nowrap
        align-items: center
        justify-content: center
        margin: 0 5px
  .chart
    margin-top: 40px
  &:hover
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 1.85692px 6.48173px rgba(0, 0, 0, 0.0462464)


