@import '@/Assets/sass/_variables';
@import '@/Assets/sass/mixins';


.wrapper {
  width: 307px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  border: 1px solid #E8E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08), 0px 5px 27px 0px rgba(0, 0, 0, 0.09);


  .typeAlert {
    display: flex;
    margin-left: 22px;
  }

  button {
    background: none;
    border: none;
    width: 50px;
    height: 50px;
    border-left: 1px solid #E8E5E5;
    font-size: 14px;
    text-transform: lowercase;
  }

  &.info {
    color: $RegularGray;
    background: #2A304208;

    button {
      color: $RegularGray;
    }
  }

  &.success {
    color: $Green_2;
    background: rgba(84, 200, 110, 0.18);

    button {
      color: $Green_2;
    }
  }

  &.error {
    color: $RedMain;
    // background: rgba(220, 0, 0, 0.18);

    button {
      color: $RedMain;
    }
  }
}

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 15px;

}