@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";


.wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;

  &.center {
    justify-content: center;
  }

  .mock {
    width: 143px;
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 5px;



    li {


      border-radius: 3px;
      background: var(--White, #FFF);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include paragraph($RegularGray);
      width: 32px;
      height: 36px;
    }

    a {
      width: 32px;
      height: 36px;
    }

    .disabled {
      color: $Grey_light;
      cursor: default;

      * {
        cursor: default;
      }
    }

    .selected {
      color: $RedAccent;
      cursor: default;

      a {
        color: $RedAccent;
        cursor: default;
      }

    }

    .break {
      cursor: default;
    }

    .previous {}

    .next {}
  }
}

.items__list {
  gap: 10px;
}