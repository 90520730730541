@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";

.wrapper {}

.containerOne {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.containerTwo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}