@import '@/Assets/sass/_variables';
@import '@/Assets/sass/mixins';



.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 7px 10px;
  gap: 10px;
  transition: all 0.3s ease 0s;

  @media (any-hover: hover) {
    &:hover {
      background: $Grey_admin;

      svg {
        circle {
          fill: #fff;
        }
      }
    }
  }

  &.active {
    background: $Green_2;
    color: #fff;
  }

}

.avatar {
  border-radius: 50%;
  height: 32px;
  width: 32px;

  svg {
    * {
      transition: all 0.3s ease 0s;
    }
  }
}

.info {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  flex-direction: column;
  gap: 3px;


}

.userData {
  display: flex;
  gap: 15px;
  align-items: center;
  color: $RegularGray;
  font-size: 12px;
  font-weight: 600;
}

.name {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.message {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}

.point {
  margin-left: auto;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: $RedAccent;
}