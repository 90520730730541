@import "../../_variables";

.generalFormBlock {
  background: white;
  display: flex;
  border-radius: 8px;
  padding: 10px 0;
  gap: 20px;
  align-items: flex-end;
  flex-wrap: wrap;

  div {
    // margin: 10px
    // position: relative;

    input {
      background: #F4F4F9;
    }
  }
}

.formList {
  display: flex;
  width: calc(100% + 20px);
  margin: 0 -10px;
  flex-direction: row;
  flex-wrap: wrap;

  &.noneFull {
    .PageEditOrNewForm {
      width: calc(100% - 40px);
    }
  }
}

#analyticMatchItemNow {
  width: 350px;
}

.searchResultList {
  width: 350px;
  height: 120px;
  border-radius: 3px;
  overflow-y: auto;
  background: white;
  box-shadow: 0 5px 27px rgba(0, 0, 0, 0.09), 0 2px 6px rgba(0, 0, 0, 0.08);
  padding: 0 !important;

  .searchResultItem {
    height: 30px;
    background: #F4F4F9;
    margin: 0;
    padding: 0 0 0 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    &.even {
      background: white;
    }
  }
}