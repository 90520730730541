@import '@/Assets/sass/_variables';
@import '@/Assets/sass/mixins';


.wrapper {
  position: relative;
  max-width: 348px;
  width: 100%;
}

.input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  position: absolute;
  z-index: -10;
}

.label {
  max-width: 348px;
  width: 100%;
  height: 175px;
  background-color: $Grey_admin;
  border: 2px dashed $Purple;
  position: relative;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &.upload {
    color: white;
    box-shadow: #000;
  }
}

.button {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: $RegularGray;
  margin-bottom: 15px;
}