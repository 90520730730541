@import '@/Assets/sass/_variables';
@import '@/Assets/sass/mixins';

.wrapper {
  flex: 1;

  .form {
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    align-items: flex-start;
    background: inherit;
  }
}

.wrapperForm {
  display: flex;
  gap: 10px;
  width: 100%;
}

.wrapperHead {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  background: #fff;
  padding: 20px;

}

.wrapperHead {}

.formWrapper {
  display: flex;
  gap: 10px;
  width: 100%
}