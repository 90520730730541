@import '@/Assets/sass/_variables';
@import '@/Assets/sass/mixins';

.wrapper {
  border-radius: 14px;
  padding: 5px 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: $GreyDark_2
}