@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";

.wrapper {
  position: relative;
}

.border {
  border-radius: 10px 10px 0 0;
  border: 1px solid $Grey_admin;
  overflow: auto;


  @include scrollbars(4px, #ccc, white);

  .table {
    border-radius: 10px 10px 0 0;
    border-top: 1px solid $Grey_admin;
  }
}

.header {
  .table {
    thead {
      background: $Grey_admin;
    }


    td {
      padding: 16px 12px;
    }
  }

}

.table {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  border-collapse: collapse;
  @include Body2($Dark_Gray);




  td,
  th {
    padding: 14px 12px;
    text-align: left;
    font-weight: 400;
    height: 55px;
  }

  thead {
    height: 56px;

  }

  tbody {
    tr {
      border-top: 1px solid #E1E7ED;
    }
  }


}