@import '@/Assets/sass/variables';
@import '@/Assets/sass/mixins';

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
}


.loginForm {
  width: 310px;

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: $Dark_admin;
  }

  button {
    width: 308px;
    margin-top: 20px;
    background: $Purple;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 1.85692px 6.48173px rgba(0, 0, 0, 0.0462464);
    border-radius: 3px;
    padding: 10px;
    color: #FFFFFF;
  }
}

.blockButton {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 308px;

  .InputCheckbox+label {
    margin: 0;
  }
}

.input {
  margin-top: 1rem;
}