@import "../../_variables"

.row
  display: flex
  width: 100%
  justify-content: space-between
  flex-direction: row
  flex-wrap: nowrap
  align-items: center
  .leftBlock
    display: flex
    align-items: center
  .rightBlock
    display: flex
    align-items: center
    a
      svg
        margin: auto 5px
    svg
      margin: auto 20px
      cursor: pointer
  button, a
    // width: 164px
    height: 32px
    left: 1032px
    top: 147px
    background: white
    box-shadow: 0 1px 2px #00000010, 0 1.85692px 6.48173px #00000004
    border-radius: 3px
    outline: none
    border: none
    font-weight: 700
    font-size: 16px
    line-height: 19px
    display: flex
    align-items: center
    color: $RegularGray
    text-decoration: none
