@import '@/Assets/sass/variables';
@import '@/Assets/sass/mixins';

:global {
  .tox-notifications-container {
    display: none;
  }
}

.wrapper {
  position: relative;

  &.simple {
    :global {
      .tox:not(.tox-tinymce-inline) .tox-editor-header {
        display: none;
      }
    }
  }

  &.errorWrapper {
    :global {
      .tox-tinymce {
        border: 1px solid $RedMain;
      }
    }
  }


  :global {
    .tox:not(.tox-tinymce-inline) .tox-editor-header {
      background-color: $Grey_admin;
    }

    .tox .tox-edit-area__iframe {
      background-color: $Grey_admin;
      padding: 0 10px;


    }

    .tox .tox-toolbar,
    .tox .tox-toolbar__overflow,
    .tox .tox-toolbar__primary {
      background-color: red;
      background-color: $Grey_admin;
    }

    // .tox-tinymce {
    //   border-radius: 3px;
    //   border: none;
    // }

  }


}

.label {}

.error {
  span {
    position: absolute;
    font-size: 10px !important;
    text-transform: none !important;
    font-weight: 400 !important;
    color: $RedMain;
    left: 12px;
  }

}