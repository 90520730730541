@import '../_variables';


.App-mainBlock {
  .startLineInfo {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0.282191px;
    color: $Dark_Gray;

    ul {
      list-style: none;
      display: flex;
      margin: 0;

      li {
        a {
          display: contents;
        }
      }
    }
  }

  .description {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: $primary_dark;
    margin: 10px 0;
    margin-bottom: 20px;
  }

  .MiniCardAnalyticsList,
  .MiddleCardAnalyticsList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -10px;
  }
}