@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";


.wrapper {
  display: flex;
  align-items: center;
  gap: 20px;

  .dashboardWrapper {
    height: 181px;
  }
}



.dashboard {
  margin-top: auto;
}


.dashboardTitle {
  color: $RegularGray;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 30px;
}


.listOne {
  display: flex;
  gap: 30px;
  flex-direction: row;

  span{
    font-size: 12px;
  }

}

.list {
  display: flex;
  gap: 10px;

}

.item {
  width: 80px;
  height: 40px;
  position: relative;
  border-radius: 50px;
  background: #D4D4D8;
  cursor: pointer;
  transition: all 0.3s ease 0s;

  color: $RegularGray;

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }


  &.active {

    background: $Purple;

    span {
      color: #fff
    }
  }
}