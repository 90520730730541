@import "../../_variables"

.BlockSchoolList
  display: flex
  justify-content: space-between
  flex-direction: row
  flex-wrap: wrap
  align-content: space-around
  align-items: center
  &.rowLine
    .SchoolCard
      width: 100%
      .description
        width: calc(100% - 120px)

