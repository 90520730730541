@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";


.wrapper {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  justify-content: flex-end;
  margin-left: 65px;

  &.wrapperUser {
    flex-direction: row-reverse;
    margin-right: 65px;
    margin-left: 0;

    .message {
      background: #D4D4D8;
    }

    .triangle {
      right: auto;
      left: -5px;
      background: #D4D4D8;

      box-shadow: 0 0 0 1px #D4D4D8;

      &::after,
      &::before {
        background: #D4D4D8;
        border-color: #D4D4D8;
        box-shadow: 0 0 0 1px #D4D4D8;
      }

    }
  }

}

.message {
  border-radius: 8px 8px 8px 0;
  background: $Grey_admin;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  align-items: center;
  letter-spacing: 0.282191px;
  color: #2A3042;
  position: relative;
  background: #F4F4F9;
  border-radius: 8px 8px 0 8px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  background: #E7E5E5;
  align-items: flex-end;
  word-wrap: break-word;
  word-break: break-all;

}

.triangle {
  position: absolute;
  right: -5px;
  bottom: 1px;
  width: 22px;
  height: 5px;
  background-color: #E7E5E5;
  border-radius: 100px;
  box-shadow: 0 0 0 1px #E7E5E5;


  &::after,
  &::before {
    content: '';
    position: absolute;
    width: 22px;
    height: 5px;
    background-color: #E7E5E5;
    top: -8px;
    border-radius: 100px;
    box-shadow: 0 0 0 1px #E7E5E5;
  }

  &::before {
    content: '';
    left: -5px;
    transform: rotate(-60deg);
  }

  &::after {
    content: '';
    transform: rotate(60deg);
    right: -5px
  }


}

.text {
  padding: 17px 11px 17px 17px;

}

.avatarBlock {
  height: 80px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: center;
}


.avatar {
  display: flex;
  border-radius: 100%;
  height: 62px;
  width: 62px;
  gap: 5px;
  border: 2px solid #6A6667;

  svg {
    width: 100%;
    height: 100%;
  }
}



.date {
  display: block;
  font-size: 10px;
  line-height: 1;
  @include caption($RegularGray)
}