@import '@/Assets/sass/variables';
@import '@/Assets/sass/mixins';


.react-datepicker {
	font-family: $GilroyFont;
}

.react-datepicker__aria-live {
	display: none;
}

.calendar {
	background-color: #fff !important;
	border-radius: 10px !important;
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.10);
	border: none !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
	height: 100%;
}

.react-datepicker-popper {
	z-index: 5;
	padding-top: 0px !important;
	right: 0px !important;
	width: 241px;
}

.react-datepicker-popper>div {
	position: relative;
}

.react-datepicker {
	position: absolute;
	right: 0px !important;
	padding: 15px !important;
	width: 100%;
	width: 292px;
	width: 241px;
	padding: 20px 10px !important;

	@include xs-block() {}
}

.react-datepicker__header {
	padding-top: 0 !important;
}

.react-datepicker__header--custom {
	position: static;
	padding: 0px !important;
}

.react-datepicker__header--custom span,
.react-datepicker__header--custom option {

	text-transform: capitalize;
	@include paragraph($RegularGray)
}

.react-datepicker__header--custom span {
	@include xs-block() {
		font-size: 14px !important;
	}
}


.react-datepicker__header--custom select {
	border: none;
}

.react-datepicker__header--custom>div:first-child>button {
	padding: 0px;
	width: 16px;
	height: 16px;
	border: none;
	background-color: transparent;
}

.react-datepicker__header--custom>div:first-child>button:first-child {
	left: 0px;
}

.react-datepicker__header--custom>div:first-child>button:last-child {
	right: 0px;
}

.react-datepicker__header--custom>div:first-child>button>svg {
	width: 16px;
	height: 16px;
}

.custom-header-datepicker {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 3px;
	margin-bottom: 10px;

	@include xs-block() {
		height: 11px;
	}
}

.custom-header__clear {
	position: absolute;
	top: -5px;
	left: 50% !important;
	transform: translate(-50%, -50%);
}

.arrow-icon-datepicker:hover {
	fill: #282425;
}

.react-datepicker__current-month {
	margin-bottom: 5px !important;
	font-weight: 500;
	color: #282425 !important;
	color: $RegularGray !important;

}

.react-datepicker__navigation {
	width: 12px !important;
	top: 50% !important;
	transform: translateY(-50%);
}

.react-datepicker__triangle {
	display: none;
}

.react-datepicker__month-container {
	width: 100%;
}


.react-datepicker__month-container,
.react-datepicker__header,
.react-datepicker__month {
	background-color: #fff !important;
	border-bottom: none !important;
}

.react-datepicker__day {

	@include caption($RegularGray);
	width: 25px !important;
	margin: 0 !important;
	height: 25px !important;
	flex: 0 0 25px;
	text-align: center;
	vertical-align: -webkit-baseline-middle;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.react-datepicker__day--in-range {
	border-radius: 100% !important;
	background: #ebe9e9 !important;

}

.react-datepicker__day--in-selecting-range {
	background: $Grey_admin !important;
	font-weight: 400 !important;
	margin-right: 0 !important;
	position: relative;
	z-index: 1;
	border-radius: 100% !important;
	width: 25px !important;

	&:not(:last-child) {
		&::after {
			background-color: #ccc;
			// content: '';
			position: absolute;
			width: 10px;
			height: 100%;
			z-index: -100;
			top: 0;
			left: 100%;
		}
	}

	&::after {
		content: none;

	}
}

// .react-datepicker__day--in-selecting-range:first-child {
// 	background: red !important;
// }




.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
	border-radius: 100% !important;
	z-index: 2;

	&::after {
		content: none !important;
	}
}

.react-datepicker__day--range-start {
	border-radius: 100% !important;

	&::after {
		// content: '' !important;
		width: 25px !important;
		left: 15px !important;
	}
}

.react-datepicker__day--range-end {
	border-radius: 100% !important;

	&::after {
		// content: '' !important;
		width: 15px !important;
		left: 0 !important;
	}


}

.react-datepicker__day--range-start {
	&.react-datepicker__day--range-end {
		&::after {
			content: none !important;
		}
	}
}

.react-datepicker__day--in-selecting-range-start,
.react-datepicker__day--in-selecting-range-end {

	&::after {
		content: none !important;
	}
}



.react-datepicker__day--outside-month {
	@include caption($GreyLight)
}




.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
	font-weight: normal !important;
}

.react-datepicker__week {
	display: flex;
	justify-content: space-between;
	// gap: 10px;
}

.react-datepicker__week:not(:last-child) {
	margin-top: 3px;
	margin-bottom: 3px;


}

.react-datepicker__day-names {
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.react-datepicker__day-name {
	text-transform: capitalize;
	@include caption($Dark_admin);

	@include xs-block() {
		font-size: 10px !important;
	}
}

.react-datepicker__day-name:not(:last-child) {
	margin-right: 8px !important;
}