@import '@/Assets/sass/_variables';
@import '@/Assets/sass/mixins';



.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}