@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";

.role {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  font-size: 15px;
}

.menuUser {
  width: 200px;
  color: $Dark_Gray;
}

.icon {
  // outline: 2px solid #353031;
  overflow: hidden;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    height: 60px;
    width: 60px;
  }
}

.skeleton {
  @include skeleton()
}