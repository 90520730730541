@import "_variables";

//Media queries
@mixin lg-block() {
  @media screen and (max-width: $break_lg) {
    @content
  }
}

@mixin md-block() {
  @media screen and (max-width: $break_md) {
    @content
  }
}


@mixin sm-block() {
  @media screen and (max-width: $break_sm) {
    @content
  }
}


@mixin xs-block() {
  @media screen and (max-width: $break_xs) {
    @content
  }
}

@mixin to($max_width) {
  @media screen and (max-width: $max_width) {
    @content
  }
}

;

//scrollbar line
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%), $border-color: transparent) {

  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: $size;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border: 1px solid $border-color;
    border-radius: $size;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color
  }
}

@mixin skeleton() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $GreyLight;
  z-index: 9;

  border-radius: 5px;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s infinite cubic-bezier(0.4, 0.0, 0.2, 1);
  }


  @keyframes load {
    from {
      left: -150px;
    }

    to {
      left: 100%;
    }
  }
}




///import css-variables from 

@mixin font-face($family, $path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $family;
    src: local('#{$path}'),
    url('#{$path}.woff2') format('woff2'),
    url('#{$path}.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}