@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #676C79;
  position: relative;
}

.value {
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  display: flex;
  align-items: center;
  letter-spacing: 0.282191px;
  color: #676C79;
  position: relative;
}

.skeleton {
  @include skeleton()
}