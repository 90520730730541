@import "../../_variables"

.Products
  display: flex
  flex-direction: row
  flex-wrap: wrap
  align-content: center
  justify-content: space-between
  .CardProduct
    width: 250px
    height: 306px
    margin: 10px
    background: #FFFFFF
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 1.85692px 6.48173px rgba(0, 0, 0, 0.0462464)
    border-radius: 10px
    padding: 20px
    display: flex
    flex-direction: column
    flex-wrap: nowrap
    align-content: flex-start
    justify-content: space-between
    align-items: flex-start
    .topBlock
      height: 110px
      display: flex
      flex-direction: row
      flex-wrap: nowrap
      align-content: center
      align-items: center
      justify-content: space-between
      .InputCheckbox + label
        margin: auto 9px auto 0
      img
        width: 220px!important
        height: 110px!important
        border: 1px solid
    .title
      @include H2_Admin($RegularGray)
    .description
      @include Body2($Dark_admin)
    .price
      @include buttons($Purple)
    .groupButton
      display: flex
      align-items: center
      justify-content: space-between
      align-content: center
      width: 100%

  &.line
    .CardProduct
      width: 100%
      height: 179px
      margin: 10px 0
      flex-wrap: wrap
      .info
        max-width: calc(100% - 350px)
        height: 100%
        margin: 15px 0 15px 17px
        display: flex
        flex-direction: column
        flex-wrap: nowrap
        justify-content: space-between
        order: 1
      .groupButton
        width: 255px
