@import '@/Assets/sass/_variables';
@import '@/Assets/sass/mixins';


.wrapper {
  position: relative;
  width: 100%;

  &.uploadWrapper {
    :global {
      .file-drop-target {
        padding: 4px;
        border-radius: 3px 3px 0 0;
        background: $Grey_admin;

      }
    }
  }

}

.input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  position: absolute;
  z-index: -10;
}

.label {
  width: 100%;
  // width: 348px;
  height: 175px;
  background-color: $Grey_admin;
  border: 2px dashed $Purple;
  position: relative;
  margin: 10px;
  display: flex;
  border-radius: 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 !important;
  transition: all 0.3s ease 0s;

  &.upload {
    color: white;
    box-shadow: #000;
    border: none !important;
    padding: 4px;
    // width: 340px;
    height: 168px;
    background: $Grey_admin;
  }

}

.button {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: $RegularGray;
  margin-bottom: 15px;
}

.type {
  color: #ccc;
}

.tooltip {
  background: $Grey_admin;
  display: flex;
  gap: 29px;
  align-items: center;
  padding: 5px;
  // width: 348px;

  .nameFile {
    overflow: auto;
    white-space: nowrap;
  }

  .buttons {
    display: flex;
    gap: 10px;
  }
}