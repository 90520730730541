@import '@/Assets/sass/variables';
@import '@/Assets/sass/mixins';


.wrapper {
  display: flex;
  gap: 30px;
  flex-direction: column;
}

.backgroundWrap {
  border-radius: 10px;
  background: #FFF;
  padding: 20px;
}

.sections {
  display: flex;
  align-items: center;
  gap: 25px;
  flex-wrap: wrap;
}

.table {
  margin-top: 24px;
}