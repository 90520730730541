@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";


.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.background {
  background: #fff;
  padding: 17px 20px 3px 20px;
  border-radius: 0 0 10px 10px;
  height: 718px;

}

.pagination {
  height: 40px;
}

.link {
  display: block;
}

.table {}

.status{
  display: inline-block;
}