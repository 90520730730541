@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";




.wrapper {
  position: relative;
  min-width: 496px;
  height: 148px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background: #fff;
  color: $RegularGray;
  transition: all 0.3s ease 0s;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05), 0px 5px 27px 0px rgba(0, 0, 0, 0.05), 0px 14px 65px 0px rgba(0, 0, 0, 0.05), 0px 15px 158px 0px rgba(0, 0, 0, 0.04);

  &.full {
    width: 100%;
  }

  .skeleton {
    @include skeleton()
  }

  &.hugo {
    height: 226px;
  }


}




.greenWrapper {
  .circle {
    background: #54C86E1A;
  }

  .circleBackground {
    stroke: inherit;
  }

  .line {
    background: $Green_2;

  }

  .progress {
    stroke: $Green_2;
  }

}

.redWrapper {
  .circle {
    background: #FF0A1333;
  }

  .circleBackground {
    stroke: inherit;
  }

  .progress {
    stroke: $RedAccent;
  }

  .line {
    background: $RedAccent;
  }
}

.line {
  width: 9px;
  height: 100%;
  border-radius: 0 5px 5px 0;
}

.contentWrapper {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  height: 100%;

  tspan {
    font-size: 15px;
  }


  &.filterWrapper {
    padding: 15px 10px 8px 20px;
    gap: 10px;
    font-size: 16px;
  }

}


.infoBlock {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  height: 108px;

  &.alignCenter {
    justify-content: center;
    gap: 10px;
  }
}

.radioBetting {
  display: flex;
  align-items: center;
  gap: 16px;

  .radio {
    display: flex;
    align-items: center;
    gap: 4px;

    span {
      background: #E2E6FF;
      border-radius: 100%;
      width: 16px;
      height: 16px;
    }

    &.activeRadio {
      span {
        background: $Purple;
      }

    }
  }
}


.title {
  @include Body2($RegularGray)
}

.infoStonks {
  display: flex;
  gap: 4px;
}

.stonks {
  height: 20px;
  border-radius: 3px;
  color: $Green;
  background: $Grey_admin;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  gap: 2px;

  &.isFall {
    color: $RedAccent;

    svg {
      transform: rotate(90deg);

      path {
        stroke: $RedAccent
      }
    }

  }
}

.text {
  font-size: 12px;
  font-style: normal;
  color: $RegularGray;
  font-weight: 400;
  display: flex;
  align-items: center;
}


.circleWrapper {
  position: relative;
}

.isBettingCircle {
  .circle {
    transform: rotate(152deg);
    background: inherit;
    height: 125px;
    width: 125px;
  }

  .circleInner {
    box-shadow: 0px 3px 23.64179039001465px rgba(105, 92, 184, 0.40);
    height: 74px;
    width: 74px;

  }

  .circleBackground {
    stroke-width: 4px;

  }

  .progress {
    stroke-width: 4px;

  }
}

.circle {
  position: relative;
  width: 99px;
  height: 99px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #E2E6FF;
  flex: 0 0 99px;
  transform: rotate(35deg);

}





.circleInner {
  height: 74px;
  width: 74px;
  background: #fff;
  align-items: center;
  display: flex;
  border-radius: 100%;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50;
  top: 50%;
  transform: translate(-50%, -50%);
}

.circleStatic {
  height: 50px;
  width: 50px;

  &.circleFall {
    svg {
      transform: rotate(90deg);
    }

    * {
      stroke: rgba(255, 0, 8, 0.8);
      fill: rgba(255, 0, 8, 0.8);
    }
  }
}

.numbersStat {
  @include H2_Admin($RegularGray)
}





.circleBackground {
  fill: none;
  stroke: #E2E6FF;
  stroke-width: 2px;
}




.progressBarWrapper {
  position: relative;
  display: flex;
  gap: 25px;

  &.difference {
    .circleInner {
      height: 54px;
      width: 54px;
    }
  }

  :global {
    .RCP {
      width: 99px !important;
      height: 99px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }



  &.large,
  &.huge {
    :global {
      .RCP {
        width: 125px !important;
        height: 125px;

      }
    }

    .backgroundCircle {
      width: 110px;
      height: 110px;
    }
  }

  &.huge {
    :global {
      .RCP {
        width: 180px !important;
        height: 180px;

      }
    }

    .backgroundCircle {
      width: 162px;
      height: 162px;
    }

    .circleInner {
      height: 82px;
      width: 82px;

    }
  }

}

.circleBar {
  position: relative;
}

.circleInner {
  box-shadow: 0px 3px 23.64179039001465px rgba(105, 92, 184, 0.40);
  height: 74px;
  width: 74px;
  z-index: 1;
}

.backgroundCircle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 88px;
  height: 88px;
  border-radius: 100%;
}

.children {
  align-items: center;
}

.tooltipWrapper {
  position: absolute;
  width: 100%;

  div {
    border-radius: 31.081px;
    padding: 8px;
    text-align: center;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.033px;
    position: absolute;
    bottom: 10px;
    z-index: 5900;
  }
}

.progressUp {
  color: #fff;
  left: 0;
  transform: translate(-30%, -110%);

}


.progressRes {
  color: $RegularGray;
  right: 0;

  transform: translate(30%, -90%);
}