@import "../_variables"

input
  width: 204px
  padding: 10px
  font-size: 12px
  line-height: 14px
  display: flex
  align-items: center
  letter-spacing: 0.300597px
  color: $Dark_Gray
  border-radius: 3px
  border: none
  outline: none


