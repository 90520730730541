@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";


.wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.leftBlock {
  display: flex;
  gap: 10px;
  align-items: center;
}

.rightBlock {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;

  a {
    svg {
      margin: auto 5px;
    }
  }

  svg {
    margin: auto 20px;
    cursor: pointer;
  }
}

.buttonActive {
  color: $RedMain !important;
}

.buttons {
  display: flex;
  align-items: center;

  :first-child {
    border-radius: 3px 0 0 3px;
  }

  :last-child {
    border-radius: 0 3px 3px 0;
  }

}