.form {
	padding: 20px !important;
}

.select {
	flex: 0 1 224px;
}

.wrapper {
	display: flex;
	border-radius: 8px;
	background: #fff;
	padding: 20px;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
}

.infoEvent {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;
}

.game {
	display: flex;
	gap: 10px;
	align-items: flex-start;
}

.opponent {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 170px;
	text-align: center;
}

.image {
	max-width: 50px;

	img {
		width: 100%;
	}
}

.versus {
	align-self: center;
}

.info {
	display: flex;
	gap: 15px;
}

.form {
	justify-self: start;
	width: 100%;
}
