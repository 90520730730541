@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";


.wrapper {
  display: flex;
  gap: 22px;
}

.info {
  flex: 0 0 292px;
}

.content {
  flex: 1 1 auto;
}