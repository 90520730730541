@import '@/Assets/sass/_variables';
@import '@/Assets/sass/mixins';


.wrapper {
  background: white;
  display: flex;
  border-radius: 8px;
  gap: 20px;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 10px 20px;
}