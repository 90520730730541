@import "../../_variables"

.PageEditOrNewForm
  background: #FFFFFF
  width: calc(50% - 60px)
  border-radius: 8px
  padding: 20px
  flex: 1
  .langTitle
    font-weight: 500
    font-size: 20px
    line-height: 24px
    display: flex
    align-items: center
    color: $Dark_admin
  .required
    font-weight: 400
    font-size: 18px
    line-height: 23px
    display: flex
    align-items: center
    letter-spacing: 0.282191px
    color: $RegularGray
  label
    font-weight: 400
    font-size: 12px
    line-height: 14px
    display: flex
    align-items: center
    letter-spacing: 0.300597px
    color: $Dark_admin
    margin: 20px 0 15px
  input, textarea
    height: 14px
    background: $Grey_admin
    border-radius: 3px
    padding: 10px
    outline: none
    border: none
    &#preview
      height: 51px
    &#text
      height: 125px
  .row
    margin-top: 20px
    align-items: flex-start
    width: 100%
    flex-wrap: wrap
    gap: 10px
    label
      height: 175px
      background-color: $Grey_admin
      border: 2px dashed $Purple
      position: relative
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      align-content: center
      background-position: center
      background-repeat: no-repeat
      background-size: cover
      button
        font-weight: 700
        font-size: 16px
        line-height: 19px
        display: flex
        align-items: center
        color: $RegularGray
        margin-bottom: 15px
    input
      width: 0.1px
      height: 0.1px
      opacity: 0
      position: absolute
      z-index: -10
  button
    height: 32px
    background: white
    box-shadow: $ShadowHover
    border-radius: 3px
    border: none
    outline: none
    color: $RegularGray
    transition: 300ms
    svg
      transition: 300ms
      path
        fill: $RegularGray
    &:hover
      color: $RedAccent
      svg
        path
          fill: $RedAccent
