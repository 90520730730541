@import '@/Assets/sass/_variables';
@import '@/Assets/sass/mixins';



.wrapper {
  padding: 19px 20px;
  background: #FFFFFF;
  border-radius: 10px;

  &.disabled {
    .button {
      color: $RegularGray
    }
  }
}

.title {
  cursor: pointer;
  width: 100%;
}

.button {
  background: none;
  border: none;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #695CB8;
  display: flex;
  align-items: center;
  gap: 7px;
  font-family: $GilroyFont;

  svg {
    transition: all 0.3s ease 0s;
  }

  &.show {
    svg {
      transform: rotate(90deg);
    }

  }

}