@import '@/Assets/sass/_variables';
@import '@/Assets/sass/mixins';





.App-nav {
  width: 313px;
  height: 100vh;
  top: 0;
  position: fixed;
  background: $primary_dark;

  .nav {
    height: calc(100vh - 130px);
    position: fixed;
    top: 0;
    left: 0;
    padding-left: 47px;
    padding-top: 64px;
    width: 100%;
    overflow-y: auto;
    @include scrollbars(4px, $Dark_Gray, $primary_dark);

    &-logo {
      padding-right: 49px;
      margin-bottom: 30px;
      display: block;
    }

    .navBlock_info {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: $Accent_Red;
      padding: 15px 0;
      justify-content: flex-start;
      text-decoration: none;
    }

    .subMenu {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 10px;
      margin-top: 6px;

      a {
        font-weight: normal;
        font-size: 15px;
        line-height: 23px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.282191px;
        color: #FFFFFF;
        text-decoration: none;
        justify-content: flex-start;
        gap: 10px;

        >span {
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.active {
          color: $Accent_Red;



          >svg {
            fill: $Accent_Red;

          }
        }

        img {
          margin-left: -12px;
        }
      }
    }
  }

  .copywriting {
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.285em;
    text-transform: uppercase;
    color: white;
    margin-top: 50px;
    position: absolute;
    bottom: 30px;
    left: -34px;
  }
}