@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";


.wrapper {
  display: flex;
  flex-direction: column;
  gap: 21px;
}

.userWrapper {
  border-radius: 10px;
  height: 723px;
  background: #fff;
  padding: 20px;
}

.image {
  margin-top: 7px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    width: 110px;
    height: 110px;
    border-radius: 100%;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.name {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: $RegularGray;
  position: relative;

}

.skeleton {
  @include skeleton()
}


.value {
  position: relative;
  @include Body2($RegularGray)
}

.userButtons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}