@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";




.wrapper {}

.background {
  flex: 1;
  gap: 20px;
  display: flex;
  background: #fff;
  padding: 17px 20px 3px 20px;
  border-radius: 0 0 10px 10px;
  height: 718px;
}

.table {
  flex: 0 1 652px;
}

.firstName {
  color: $Dark_admin;
}

.pagination {
  margin-top: 10px;
  max-width: 652px;
  width: 100%;
}