@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";


.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.background {
  background: #fff;
  padding: 17px 20px 3px 20px;
  border-radius: 0 0 10px 10px;
  height: 718px;
}

.pagination {
  height: 40px;
  background: rgba(0, 128, 0, 0.411);
}

.amount {
  @include Body2($Green_2);

  &.isFall {
    color: $RedAccent
  }
}

.calendar {
  display: flex;
  align-items: center;
  max-width: 217px;
  gap: 5px;

}