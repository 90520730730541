@import '@/Assets/sass/_variables';
@import '@/Assets/sass/mixins';

.button {
  padding: 0 22px;
  background: rgb(105, 92, 184);
  color: rgb(255, 255, 255);
  width: 100%;
  height: 40px;
  font-family: $GilroyFont;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  border: none;
  gap: 8px;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  transition: all 0.3s ease 0s;

  @media (any-hover: hover) {
    &:hover {
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08), 0px 5px 27px 0px rgba(0, 0, 0, 0.09);

    }
  }


  &.inverse {
    background: #FFFFFF;
    color: $Dark_Gray;

    @include xs-block() {
      height: 40px;
    }

  }


  &.success {
    border-radius: 5px;
    background: #7EAC2F;
    text-transform: uppercase;
    color: #fff;
    padding: 15px;
    max-width: 261px;
    width: 100%;
  }

  &.fullWidth {
    max-width: 100%;
  }

  &.small {
    padding: 11px 15px;
    height: 32px;
    margin-bottom: 0px;
    box-shadow: $Shadow;
    border-radius: 3px;
    outline: none;
    border: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: $Dark_Gray;
    text-decoration: none;

  }

  &.disabled {
    background: linear-gradient(180deg, #231F20 2.08%, #2E2A2B 45.37%, #231F20 100%);
    background: #fff;
    // box-shadow: 0 3px 42px rgba(0, 0, 0, 0.5);
    color: #b0b0b0;
    transform: scale(1);
    cursor: default;

  }

  &.red {
    color: $RedAccent;
  }



}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  svg {
    height: 100%;
    width: 10px;
    margin-right: 0;
  }
}

.text {
  white-space: nowrap;
}