@import '@/Assets/sass/variables';
@import '@/Assets/sass/mixins';


.wrapper {
  display: flex;
  gap: 30px;
  flex-direction: column;
}

.backgroundWrap {
  border-radius: 10px;
  background: #FFF;
  padding: 20px;
}

.sections {
  display: flex;
  align-items: center;
  gap: 25px;
}

.table {

  th,
  td {
    padding: 7px 12px !important;
  }

}




.buttons {
  display: flex;
  align-items: center;

  gap: 10px;
}

.filterButton {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border: none;
  border-radius: 3px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 23px;
    height: 23px;
    flex: 0 0 23px;
  }

  &.activeButton {

    path {
      fill: $RedAccent;
      stroke: $RedAccent;
    }
  }
}

.wrapperFilter {
  background: #fff;
  color: black;
  padding: 39px 20px 15px 20px;
  width: 282px;


  .title {
    @include Body_admin($RegularGray);
    margin-bottom: 18px;
  }

  .titleFilter {
    @include caption($RegularGray);
    margin-bottom: 17px;
  }

  .buttons {
    margin-top: 20px;
    gap: 8px;

    span {
      font-size: 13px;
      font-weight: 600;
    }
  }


}