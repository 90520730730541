.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 100%;
}

.forms {
  display: flex;
  gap: 10px;
}