@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";

.list {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.select {
  min-width: 125px;
}

.react {
  width: 95px;

  :global {
    .react-select__indicator {
      padding-right: 0;
    }

    .react-select__value-container {
      padding-right: 0;
    }
  }
}

.tooltip {
  border-radius: 50px;
  background: var(--Color-white, linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%), #FFF);
  box-shadow: 0px 1px 8px 0px rgba(83, 85, 155, 0.18);
  padding: 7px 13px;
  font-size: 12px;
  color: $RegularGray;

  .label {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  span {
    font-size: 20px;
  }
}