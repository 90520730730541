@import '@/Assets/sass/_variables';
@import '@/Assets/sass/mixins';

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#231F20, 0.9);
  z-index: 999;

  &.blur {
    background: rgba(44, 40, 41, 0.50);
    backdrop-filter: blur(5px);
    padding: 0;
  }

  &.absolute {
    position: absolute;
  }
}

.modalContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 15px;
  display: flex;
  z-index: 999;
}

.modal {
  margin: auto;
  padding: 10px;
  max-width: 700px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  background: #ffffff;
  box-shadow: 0 10px 64px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  border: 10px solid rgba(177, 169, 171, 0.40);
  background: linear-gradient(135deg, #231F20 66.48%, #323232 100%);

  &.zeroOffset {
    padding: 0;
  }

  &.tiny {
    padding: 25px 30px 15px 30px;
    max-width: 390px;
  }

  &.small {
    max-width: 500px;
    width: 100%;
    padding: 20px 28px;

    @include xs-block {
      padding: 25px 10px;
      max-width: 290px;
    }
  }

  &.simple {
    background: $black;
    border-radius: 10px;
    border: 1px solid $GrayDark;

    .close {
      transform: translate(-0, 50%);
    }
  }

  &.conformation {
    border: none;
    background: $Grey_admin;
    max-width: 584px;
    min-height: 298px;
  }

  &.light {
    color: $GrayDark;
    background: #fff;
  }

  @media (max-width: 1024px) {
    max-width: 650px;
  }

  @include sm-block {
    max-width: none;
    padding: 25px 20px;
  }
}

.close {
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-0, 50%);
  border: none;
  background: none;
  cursor: pointer;
  z-index: 1000;

  svg {
    fill: $GrayDark;

    path {
      fill: $GrayDark;
    }
  }

  @include sm-block {
    top: 12px;
    right: 12px;
  }
}

.title {
  padding-bottom: 32px;
  margin-right: 20px;
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  border-bottom: 1px solid #E0E0E0;

  .modalLarge & {
    padding: 13px 0 33px;
    text-align: left;
    border-bottom: none;
  }

  @include sm-block {
    font-size: 24px;
    line-height: 28px;
    max-width: 85%;
    text-align: left;
    padding-bottom: 10px;
    border-bottom: none;
  }
}

.title+.body {
  margin-top: 26px;

  @include sm-block {
    margin-top: 15px;
  }
}

.body {
  flex-grow: 1;
  margin-left: -2px;
  position: relative;
  //overflow-y: auto;
  //overflow-x: hidden;
}

//@include scrollbars(4px, $primary, $main-white);