@import '@/Assets/sass/variables';
@import '@/Assets/sass/mixins';

.wrapper {
  padding: 16px;
  border-radius: 10px;
  background: #F4F4F9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.title {
  color: #697077;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.percent {
  position: relative;
  background: $Purple;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  width: 85px;
  height: 35px;
  color: #fff;
  border-radius: 50px;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}