@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";


.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.background {
  background: #fff;
  padding: 17px 20px 3px 20px;
  border-radius: 0 0 10px 10px;
  height: 658px;

  td {
    padding: 9px 12px;
  }

}

.pagination {
  height: 40px;
}

.link {
  display: block;
}

.table {}


.filterButton {
  cursor: pointer;
}

.wrapperFilter {
  width: 180px;
}



.select {
  width: 80px;

  :global {
    .react-select__menu-list {
      max-height: 120px;
      font-size: 15px;
    }

    .react-select__single-value {
      font-size: 15px;
    }

    .react-select__indicator {
      font-size: 15px;
    }

    .react-select__menu-list {
      div {
        font-size: 15px;
      }
    }
  }
}