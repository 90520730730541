@import '@/Assets/sass/_variables';
@import '@/Assets/sass/mixins';



.search {
  overflow: hidden;
  border-radius: 10px;
  background: #fff;
  padding: 10px;

  flex: 0 0 auto;

  .input {


    input {
      padding-left: 50px;
    }

  }
}

.input {
  padding: 2px 0;

  svg {
    width: 34px;
    height: 34px;
  }



}

.list {
  overflow: hidden;

  border-radius: 10px;
  display: none;
  overflow: auto;
  @include scrollbars(4px, #ccc, white);

  &.show {
    display: block;
    height: 100%;
    max-height: 260px;

  }
}


.listAppeals {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 5px;
  padding-top: 4px;
  max-height: 280px;
  overflow: auto;
  @include scrollbars(4px, #ccc, white);
}