@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";




.wrapper {
  position: relative;
  min-width: 496px;
  height: 148px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  background: #fff;
  color: $RegularGray;
  transition: all 0.3s ease 0s;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05), 0px 5px 27px 0px rgba(0, 0, 0, 0.05), 0px 14px 65px 0px rgba(0, 0, 0, 0.05), 0px 15px 158px 0px rgba(0, 0, 0, 0.04);

  &.full {
    width: 100%;
  }

  .skeleton {
    @include skeleton()
  }

  &.hugo {
    height: 226px;
  }

}


.contentWrapper {
  padding: 20px;
  flex: 1;
  height: 100%;
  display: flex;
}

.line {
  width: 9px;
  height: 100%;
  background: $Purple;
  border-radius: 0 5px 5px 0;
}