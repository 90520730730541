@import '../_variables'

.react-datepicker-wrapper
  width: auto
  .react-datepicker__input-container
    width: auto
    button
      width: 110px!important
      height: 29px
      background: $BG_Gray
      border: 1px solid $BG_Gray
      box-sizing: border-box
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 1.85692px 6.48173px rgba(0, 0, 0, 0.0462464)
      border-radius: 3px
      display: flex
      flex-direction: row
      flex-wrap: nowrap
      align-items: center
      justify-content: center
      margin: 0 5px
