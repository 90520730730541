@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";

.wrapper {
  padding: 8px 12px;
  background: #F0FFF8;
  @include Body2($Green_2);
  border-radius: 50px;
  text-align: center;

  &.active {
    background: #FFF0F0;
    color: $RedMain;

  }

  &.wait {
    background: #F1F1F1;
    color: $RegularGray;
  }

  &.progress {
    background: #FFD21540;
    color: #FBB433;
  }
}