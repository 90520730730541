@import './_variables';
@import './mixins.scss';
@import './fonts.scss';

html,
body {
  margin: 0;
  padding: 0;
  background: $Grey_admin;
  font-family: $GilroyFont;
  font-weight: $GilroyRegular;

  * {
    box-sizing: border-box;
  }
}


.hidden {
  display: none;
}

.tox-notifications-container {
  display: none !important;
  z-index: -1;
}

.tox.tox-silver-sink.tox-tinymce-aux {
  z-index: -1 !important;

}


img {
  max-width: 100‰;
}

button,
a {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: inherit;
  text-decoration: none;


}

.alert {
  width: 307px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  border: 1px solid #E8E5E5;
  box-sizing: border-box;
  border-radius: 3px;

  .typeAlert {
    display: flex;
    margin-left: 22px;
  }

  button {
    background: none;
    border: none;
    width: 50px;
    height: 50px;
    border-left: 1px solid #E8E5E5;
  }

  &.info {
    color: $RegularGray;
    background: #2A304208;

    button {
      color: $RegularGray;
    }
  }

  &.success {
    color: $Green_2;
    background: rgba(84, 200, 110, 0.18);

    button {
      color: $Green_2;
    }
  }

  &.error {
    color: $RedMain;
    background: rgba(220, 0, 0, 0.18);

    button {
      color: $RedMain;
    }
  }
}

.tox {

  .tox-notification--warn,
  .tox-notification--warning,
  .tox-statusbar {
    display: none !important;
  }
}

.react-datepicker-popper {
  .react-datepicker {
    .react-datepicker__triangle {
      &:before {
        border-bottom-color: $Grey_light;
      }

      &:after {
        border-bottom-color: $Grey_light;
      }
    }

    .react-datepicker__navigation {
      border: none !important;
      background: none !important;
      box-shadow: none !important;
      top: 20px;

      span:before {
        border-color: $RegularGray;
      }
    }

    .react-datepicker__month-container {
      .react-datepicker__header {
        background: #D4D4D8;
        box-shadow: 0 1px 2px #00000001, 0 1.85692px 6.48173px #00000001;

        .react-datepicker__current-month {
          font-weight: 500;
          font-size: 16px;
          line-height: 25px;
          color: $RegularGray;
          margin: 13px;
        }

        .react-datepicker__day-name {
          color: $Dark_admin;
        }
      }

      .react-datepicker__month {
        background: $Grey_admin;
        border-radius: 0 0 3px 3px;
        margin: 0;

        .react-datepicker__day {
          color: $RegularGray;

          &.react-datepicker__day--selected {
            background-color: white;
            border-radius: 100px;
          }

          &.react-datepicker__day--outside-month {
            color: $Grey_light;
          }

          &.react-datepicker__day--in-selecting-range,
          &.react-datepicker__day--in-range {
            margin: 0;
            padding: 0.166rem;
            border: none;
            outline: none;
            background-color: white;

            &.react-datepicker__day--selecting-range-start,
            &.react-datepicker__day--range-start {}

            &.react-datepicker__day--selecting-range-end,
            &.react-datepicker__day--selecting-range-end {}
          }
        }
      }
    }
  }
}



#root {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;

  .App {
    min-height: 100%;

    .App-nav {
      transform: scale(0);
    }

    .AppMainBlock,
    .App-footer {
      padding: 10px 35px;
    }

    .AppMainBlock,
    .App-footer {
      // width: calc(100vw - 70px);
      margin: 0;
    }

    .App-header {
      width: calc(100vw);
      margin: 0;
    }

    .AppMainBlock {
      margin-top: 84px;
    }

    &.nav_active {
      .App-nav {
        transform: scale(1);
      }

      .AppMainBlock,
      .App-footer {
        width: calc(100vw - 313px);
        margin-left: 313px;
      }

      .App-header {
        width: calc(100vw - 313px);
        margin-left: 313px;
      }
    }
  }
}