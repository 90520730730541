@import '@/Assets/sass/variables';
@import '@/Assets/sass/mixins';


.wrapper {
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.input {
  max-width: 325px;
  width: 100%;


}

.blockFilter {
  margin-top: 16px;

  span {
    font-size: 15px;
  }
}

.calendar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
  max-width: 253px;
  width: 100%;

}

.buttons {
  display: flex;
  align-items: center;

  gap: 10px;
}

.filterButton {
  width: 40px;
  height: 40px;
  background-color: #F4F4F9;
  border: none;
  border-radius: 3px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;


  svg {
    width: 23px;
    height: 23px;
    flex: 0 0 23px;
  }

  &.activeButton {

    path {
      fill: $RedAccent;
      stroke: $RedAccent;
    }
  }
}

.wrapperFilter {
  background: #fff;
  padding: 36px 20px 39px 20px;
  min-width: 292px;
  color: black;

  .title {
    @include Body_admin($RegularGray);
    margin-bottom: 18px;
  }

  .titleFilter {
    @include caption($RegularGray);
    margin-bottom: 17px;
  }

  .buttons {
    margin-top: 30px;
    gap: 8px;
  }


}