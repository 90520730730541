@import './_variables'

.BlockLogin
  display: flex
  .leftBlock, .rightBlock
    width: 50vw
    height: 100vh
  .rightBlock
    background: $Dark_admin
    svg
      width: 100%
      height: 100%
  .leftBlock
    background: #FFFFFF
    .LoginForm
      width: 310px
      height: 440px
      .title
        font-weight: 700
        font-size: 20px
        line-height: 24px
        color: $Dark_admin
      .inputBlock
        label
          font-weight: 500
          font-size: 14px
          line-height: 25px
          color: $RegularGray
          height: auto
          // margin-top: 20px
          input
            width: 288px
            height: 34px
            background: $Grey_admin
            border-radius: 3px
            font-weight: 400
            font-size: 12px
            line-height: 14px
            display: flex
            align-items: center
            letter-spacing: 0.300597px
            color: $RegularGray
            flex: none
            order: 0
            flex-grow: 0
            margin: 10px 0
      .blockButton
        margin-top: 20px
        display: flex
        justify-content: space-between
        width: 308px
        .InputCheckbox + label
          margin: 0
      button
        width: 308px
        margin-top: 20px
        background: $Purple
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 1.85692px 6.48173px rgba(0, 0, 0, 0.0462464)
        border-radius: 3px
        padding: 10px
        color: #FFFFFF

