@import '@/Assets/sass/_variables';
@import '@/Assets/sass/mixins';





.confirmationBlock {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  max-width: 380px;
  margin: 0 auto;
  margin-top: 46px;


  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $Dark_admin;
  }

  .articleName {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.282191px;
    color: $Dark_admin;
  }
}

.groupButton {
  margin-top: 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  button {
    background: #FFFFFF;
    box-shadow: 0 1px 2px #00000010, 0 1.85692px 6.48173px #00000040;
    border-radius: 3px;
    width: 160px;
    height: 40px;
    padding: 10px;
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $RegularGray;

    &.colorRed {
      color: $RedAccent;
    }
  }
}