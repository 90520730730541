@import '@/Assets/sass/variables';
@import '@/Assets/sass/mixins';



.checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &.fullWidth {
    width: 100%;
  }

  &.centerPosition {
    .checkbox__textBlock {
      margin: auto;
    }
  }

  &.disabled {
    cursor: default;
  }

  &:hover {
    .checkbox__text {
      color: $GrayDark;
    }
  }

  &.disabled {
    &:hover {
      .checkbox__check {
        border: 1px solid transparent;
      }

      .checkbox__text {
        color: inherit;
      }
    }
  }
}


.redWrapper {
  .checkbox__check {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 2px solid $GreyLight;

    &::after {
      display: none;
    }

    &::before {
      content: none;
      display: none;
    }
  }

  .checkbox__input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      +.checkbox__textBlock {
        background: red;
        color: red
      }

      +.checkbox__check {
        background: $RedMain;
      }
    }
  }
}

.checkbox__input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;

  &:checked {
    +.checkbox__textBlock {
      background: red;
      color: red
    }

    +.checkbox__check {

      &:before {
        content: ''
      }

    }
  }
}

.checkbox__check {
  position: relative;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &::after {
    content: '';
    height: 12px;
    width: 12px;
    background: $GrayDark;
    position: absolute;
    top: 0;
    transform: translate(0, -50%);
  }

  &:before {
    position: absolute;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    left: 5px;
    width: 6px;
    height: 3px;
    border-radius: 1px;
    border-left: 1.5px solid white;
    border-bottom: 1.5px solid white;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    top: 50%;
    transform: rotate(-45deg) translateY(-100%);
    z-index: 2;
  }
}


.card {
  .checkbox__check {
    background: #666a77;
    height: 14px;
    width: 36px;
    border-radius: 10px;

    &::after {
      display: none;
    }

    &::before {
      content: '';
      transition: all 0.3s ease 0s;
      border-radius: 100%;
      height: 20px;
      width: 20px;
      background: #fff;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.10);
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      border: none;

    }
  }

  .checkbox__input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      +.checkbox__textBlock {
        background: red;
        color: red
      }

      +.checkbox__check {
        background: #fc7878;

        &::before {
          background: #fc0709;
          left: 100%;
          transform: translate(-100%, -50%);
        }

      }
    }
  }

  .checkbox__textBlock {
    margin-left: 0;
    order: -1;
    margin-right: 10px;

  }

  .checkbox__text {
    padding-left: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 400;
    width: 36px;
  }
}

.checkbox__textBlock {
  display: inline;
  margin-left: 20px;
}

.checkbox__text {
  font-weight: 500;
  font-size: 14px;
  // color: $GreyLight;
  color: $GrayDark;

  transition: all 0.2s ease-in-out;
  padding-left: 1rem;
  // line-height: 1.2;

  .tooltip-info {
    margin-left: 10px;
    position: relative;
    top: 4px;
  }
}

.checkbox__bracket {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: blue
}

.checkboxWrap {
  position: relative;
  display: flex;
  width: auto;
  min-height: 16px;
}