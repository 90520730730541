@import '@/Assets/sass/variables';

.wrapper {
  position: relative;

}

.input {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 100px;
  border: none;
  background: $Grey_admin;
  border-radius: 3px;
  transition: all 0.3s ease 0s;

  @media (any-hover: hover) {
    &:hover {
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);

    }
  }

  >input {
    height: 100%;
    height: 40px;
    height: 29px;
    padding: 0 10px 0 10px;
    border: none;
    margin: 0;
    width: 100%;
    font-size: 13px;
    color: $GreyDark_3;
    font-weight: 500;
    background: transparent;
    @include caption($RegularGray);


    &::placeholder {
      color: $GreyLight_2
    }
  }

  >svg {
    position: absolute;
    right: 5px;
    cursor: text
  }
}

.icon {
  display: flex;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  >div {
    display: flex;
  }

  .delete {
    &.hidden {
      display: none;
    }

    svg {
      transform: scale(0.8);
    }
  }

  svg {

    width: 15px;
    height: 15px;
  }

}